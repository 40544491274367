import {
    propertyService
} from '@/api/utils/request'
const apiUrl = {
    GetSecurityCheckList: '/api/safecheck/gets',
    CreateSecurityCheck: '/api/safecheck/create',
    UpdateSecurityCheck: '/api/safecheck/update',
    GetSecurityCheck: '/api/safecheck/get',
    DeleteSecurityCheck: '/api/safecheck/delete',
    SecurityCheckChanged: '/api/safecheck/changed',
    CheckSecurityCheckChanged: '/api/safecheck/examined',
    DeleteOthardanger: '/api/safecheck/othardangerdelete',
    UpdateOthardanger: '/api/safecheck/othardangerupdate',
    CreateOthardanger: '/api/safecheck/othardangercreate'
}

const securitycheckApi = {
    //获取安全检查列表
    GetSecurityCheckList(parameter) {
        return propertyService({
            url: apiUrl.GetSecurityCheckList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //新建一个安全检查
    CreateSecurityCheck(parameter) {
        return propertyService({
            url: apiUrl.CreateSecurityCheck,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //整改一个安全检查
    SecurityCheckChanged(parameter) {
        return propertyService({
            url: apiUrl.SecurityCheckChanged,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //审核一个安全检查
    CheckSecurityCheckChanged(parameter) {
        return propertyService({
            url: apiUrl.CheckSecurityCheckChanged,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //获取一个安全检查
    GetSecurityCheck(parameter) {
        return propertyService({
            url: apiUrl.GetSecurityCheck,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //修改一个安全检查
    UpdateSecurityCheck(parameter) {
        return propertyService({
            url: apiUrl.UpdateSecurityCheck,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除一个政安全检查
    DeleteSecurityCheck(parameter) {
        return propertyService({
            url: apiUrl.DeleteSecurityCheck,
            method: 'delete',
            params: parameter,
            needToken: true
        })
    },
    CreateOthardanger(parameter) {
        return propertyService({
            url: apiUrl.CreateOthardanger,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //修改一个安全检查
    UpdateOthardanger(parameter) {
        return propertyService({
            url: apiUrl.UpdateOthardanger,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //删除一个政安全检查
    DeleteOthardanger(parameter) {
        return propertyService({
            url: apiUrl.DeleteOthardanger,
            method: 'delete',
            params: parameter,
            needToken: true
        })
    },
}


export {
    securitycheckApi
}