<template>
  <div class="card border-0">
    <div class="card-header f-w-600">其他隐患</div>
    <div class="card-body height-auto m-0 p-0">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >其他隐患详情：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <input
                  class="form-control"
                  v-model="Othardanger.dangerDetail"
                  required
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >照片：</label
              >
              <div class="page-content col-lg-9 col-md-9">
                <div>
                  <upload
                    :maxSize="10"
                    :maxFiles="10"
                    :value="fileIds"
                    @finishUpload="finishAll"
                  >
                  </upload>
                </div>
              </div>
            </div>
            <!-- radio-begin -->
            <div class="form-group row">
              <label
                class="col-xxl-2 col-lg-4 col-md-4 col-form-label text-right"
                >状态：</label
              >
              <div class="col-xxl-5 col-lg-8 col-lg-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    id="typeRadio"
                    v-model="Othardangerstate"
                    name="type"
                    value="0"
                    checked
                  />
                  <label for="typeRadio" class="m-r-15"> 待整改</label>
                  <input
                    type="radio"
                    id="typeRadio1"
                    v-model="Othardangerstate"
                    name="type"
                    value="1"
                  />
                  <label for="typeRadio1"> 已整改</label>
                </div>
              </div>
            </div>
            <!-- radio-end -->
          </div>
        </div>
      </form>
      <div class="card-footer text-right f-w-600">
        <button
          class="btn btn-sm btn-success m-r-10"
          type="submit"
          @click="checkForm"
        >
          <i class="fa fa-save"></i> 保存
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-sm btn-grey m-r-10"
        >
          ❌关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { securitycheckApi } from "@/api/securitycheck/securitycheckApi";

export default {
  props: ["OthardangersData"],
  data() {
    return {
      Othardangerstate: 0,
      Othardanger: {
        safeCheckId: "",
        dangerDetail: "",
        fileId: [],
      },
      fileIds: [],
      id: "",
    };
  },
  created() {},
  mounted() {
    if (this.OthardangersData.id) {
      let that = this;
      that.Othardangerstate = this.OthardangersData.state;
      that.Othardanger = this.OthardangersData;
      that.fileIds = [this.OthardangersData.fileId.split(",")][0];
    } else {
      this.Othardanger.safeCheckId = this.OthardangersData;
    }
  },
  methods: {
    checkForm: function(e) {
      e.preventDefault();
      const params = {
        SafeCheckId: this.Othardanger.safeCheckId,
        DangerDetail: this.Othardanger.dangerDetail,
        FileId: this.Othardanger.fileId.toString(),
        state:this.Othardangerstate,
        Id: this.OthardangersData.id,
      };

      let that = this;
      if (that.OthardangersData.id) {
        securitycheckApi
          .UpdateOthardanger(params)
          .then((res) => that.UpdateOthardangerSuccess(that, res))
          .finally(() => {});
      } else {
        securitycheckApi
          .CreateOthardanger(params)
          .then((res) => that.CreateOthardangerSuccess(that, res))
          .finally(() => {});
      }
    },

    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },

    CreateOthardangerSuccess(that, res) {
      if (res.success) {
        this.ToastSuccess(res);
        this.$emit("close");
      } else {
        this.ToastWarning(res);
      }
    },
    UpdateOthardangerSuccess(that, res) {
      if (res.success) {
        this.ToastSuccess(res);
        this.$emit("close");
      } else {
        this.ToastWarning(res);
      }
    },

    finishAll(e) {
      this.Othardanger.fileId = e;
      console.log(e);
      this.fileIds = e;
    },
  },
};
</script>
